.resume {
   max-width: 80%;
   margin-left: auto;
   margin-right: auto;

   hr {
      height: 1px;
      color: lightgray;
      background: lightgray;
      font-size: 0;
      border: 0;
   }

   .contact {
       float: right;
   }
   
   .date {
       float: right;
       font-style: italic;
   }

   .location {
      font-style: italic;
      padding-left: 2em;
   }
}

.tech ul {
   display: inline-block;
   *display: inline;
   zoom: 1
}

.category {
    font-style: italic;
}

.stuff {
   margin-left: 0.5em;
}

#res-footer {
   text-align: center;
}

#wq {
   display: inline-block;
   font-size: 90%;
   color: #666;
}
